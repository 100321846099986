// Wait for the DOM to fully load
document.addEventListener('DOMContentLoaded', function() {
    // Select all links in the topbar with class ssp-topbar__icon
    const topbarLinks = document.querySelectorAll('.ssp-topbar__item > a');

    // Iterate over each link
    topbarLinks.forEach(function(link) {
        // Add a click event listener to each link
        link.addEventListener('click', function(event) {

            // Get the href attribute of the clicked link
            const href = link.getAttribute('href');

            // Check if the href starts with "#"
            if (href.startsWith('#')) {
                // Prevent the default behavior of the link
                event.preventDefault();

                // Get the target block using the href
                const targetBlock = document.querySelector(href);

                // Check if the clicked link is already active
                const isActive = link.classList.contains('active');

                // Remove the "active" class from all topbar blocks and links
                document.querySelectorAll('.ssp-topbar__block').forEach(function(block) {
                    block.classList.remove('active');
                });

                document.querySelectorAll('.ssp-topbar__item > a').forEach(function(itemLink) {
                    itemLink.classList.remove('active');
                });

                // If the clicked link was not active, add "active" to the link and block
                if (!isActive && targetBlock) {
                    link.classList.add('active');
                    targetBlock.classList.add('active');
                }
            }
        });
    });
});